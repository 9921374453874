import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import Hero from '@components/Article/Hero'
import Content from '@components/Article/Content'
import Terms from '@components/Article/Terms'

const Offer = ({ data, location }) => {
  const {
    seo,
    slug,
    title,
    introImage,
    content,
    sidebarLinkLists,
    showSocialShareLinks,
    termsConditions,
  } = data.contentfulOffer

  return (
    <>
      <Seo data={seo} slug={`offers/${slug}`} />
      <Hero title={title} image={introImage} />
      <Content
        title={title}
        content={content}
        sidebarLists={sidebarLinkLists}
        showSocial={showSocialShareLinks}
        backLink={{ title: 'Offers', slug: '/offers' }}
      />
      {termsConditions && <Terms content={termsConditions} />}
      <Footer location={location} />
    </>
  )
}

export default Offer

export const pageQuery = graphql`
  query Offer($slug: String!) {
    contentfulOffer(slug: { eq: $slug }) {
      seo {
        ...SEO
      }
      slug
      title
      introImage {
        gatsbyImageData
        description
      }
      content {
        raw
        references {
          ... on Node {
            ...Asset
            ...Link
            ...InternalPages
            ...HighlightedText
          }
        }
      }
      sidebarLinkLists {
        title
        links {
          ...Link
        }
      }
      showSocialShareLinks
      termsConditions {
        raw
      }
    }
  }
`
